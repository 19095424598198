/* Stock ticker container */
.stock-ticker {
  font-size: 15px;
  padding-block: 4px;
  overflow: hidden;
  user-select: none;
  --gap: 20px;
  display: flex;
  flex-direction: column; /* Arrange content vertically if needed */
  align-items: center; /* Vertically centers the text */
  justify-content: center; /* Horizontally centers the text */
  position: sticky; /* Makes the ticker sticky */
  top: 0; /* Sticks the ticker to the top of the viewport */
  z-index: 50; /* Ensures the ticker appears above other content */
  background-color: blue;
  color: white;
  text-align: center; /* Ensures proper alignment for multi-line text */
  padding: 5px 10px;
}

/* Close icon style */
.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 20px;
  color: white;
  background-color: transparent;
}

/* Styling for company name and price */
.stock-ticker .company {
  font-weight: bold;
  text-align: center; /* Ensures text alignment for longer names */
  white-space: normal; /* Allow the text to wrap */
  word-wrap: break-word; /* Break long words if needed */
  overflow-wrap: break-word; /* Ensures wrapping in modern browsers */
  padding: 5px;
}

/* Animation for scrolling the ticker */
@keyframes scroll {
  0% {
    transform: translateX(100%); /* Start from the right side */
  }
  100% {
    transform: translateX(-100%); /* Move out of view to the left side */
  }
}

/* Only show stock ticker when it's visible */
.stock-ticker.hidden {
  display: none; /* This removes the stock ticker and gap */
}

/* Styling for company name and price */
.stock-ticker .company,
.stock-ticker .price {
  font-weight: bold;
}

.stock-ticker .price {
  margin-inline: 4px;
}

.stock-ticker .plus::before {
  content: "↑";
}

.stock-ticker .plus .change,
.stock-ticker .plus::before {
  color: #089981;
}

.stock-ticker .minus .change,
.stock-ticker .minus::before {
  color: #f23645;
}
